import React from 'react';
import Image1 from "@/assets/images/124766.png";
import Image2 from "@/assets/images/2149399287.png";
import Image3 from "@/assets/images/96140.png";
import Image22 from "@/assets/images/2.png";
import { ExploreButton } from "@/util/Button/Buttons";

export const OurCatalogues = () => {
  return (
    <>
      <section className="lg:h-[900px]">
        <div className="mx-auto">
            <div className="">
                <h2 className="lg:text-3xl text-2xl font-bold mb-12 bg-gradient-to-r from-pink-500 to-blue-500 rounded-tl-[0px] rounded-tr-[1000px] rounded-br-[1000px] rounded-bl-[0px] py-6 px-8 lg:py-12 lg:px-8 inline-block text-white text-right w-[90%] lg:w-[500px] lg:text-center">
                Our Catalogues
            </h2>
             <div className="lg:w-[420px] lg:h-[350px] flex lg:float-right relative lg:bottom-[40px] w-[350px] h-[350px] py-4 px-4">
                   <img src={Image1} className="lg:mt-[40px]"/>
                   <div className="lg:mt-[240px] lg:p-4 p-2 mt-[140px]">
                        <span>Catalogues</span>
                       <ExploreButton/>
                   </div>
                </div>
            </div>
            <div className="flex items-center space-x-2 ml-[130px] relative bottom-[30px] hidden">
              <button className="text-pink-500 hover:text-pink-700">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                </svg>
              </button>

              <div className="relative w-[200px] h-3 rounded-full bg-gradient-to-r from-pink-300 to-blue-300">
                <div className="absolute h-3 bg-pink-500 rounded-full" style={{width:"20%"}}></div>
              </div>
              <button className="text-blue-500 hover:text-blue-700">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
            <div className="container mx-auto lg:flex grid gap-8 py-4 px-4 justify-items-center items-center text-center">
                <div className="lg:w-[396px] lg:h-[350px] flex lg:mt-[130px] w-[350px] h-[350px]">
                   <img src={Image2} />
                   <div className="lg:mt-[240px] lg:p-4 p-2 mt-[140px]">
                        <span>Catalogues</span>
                        <ExploreButton/>
                   </div>
                </div>
                 <div className="lg:w-[865px] lg:h-[640px] flex relative lg:bottom-[150px] w-[350px] h-[350px]">
                   <img src={Image3} className="lg:block hidden"/>
                   <img src={Image22} className="lg:hidden block h-[380px]"/>
                   <div className="lg:mt-[520px] lg:p-4 p-2 mt-[140px]">
                        <span>Catalogues</span>
                        <ExploreButton/>
                   </div>
                </div>
            </div>
        </div>
    </section>
    </>
  );
};

export default OurCatalogues;