import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import React, { useRef, useState } from "react";

export const TestiMonial = () => {
  const swiperRef = useRef(null); // Create a reference for the Swiper instance
  const [progress, setProgress] = useState(0); // State to track progress

  const handlePrevClick = () => {
    swiperRef.current.swiper.slidePrev(); // Go to previous slide
  };

  const handleNextClick = () => {
    swiperRef.current.swiper.slideNext(); // Go to next slide
  };

  const handleSlideChange = (swiper) => {
    // Update the progress bar width when the slide changes
    const progressPercentage = (swiper.realIndex / (swiper.slides.length - 1)) * 100;
    setProgress(progressPercentage); // Update progress based on the current slide
  };

  return (
    <section className="lg:h-[600px]">
      <div className="mx-auto">
        <div className="lg:flex gap-8">
          <h2 className="lg:text-3xl text-2xl font-bold lg:mb-12 bg-gradient-to-r from-pink-500 to-blue-500 rounded-tl-[0px] rounded-tr-[1000px] rounded-br-[1000px] rounded-bl-[0px] lg:py-8 lg:px-8 py-4 px-4 inline-block text-white text-center lg:w-[40%] w-[90%]">
            What People Think About Us
          </h2>
          <div className="items-start gap-4 px-4 py-2">
            <p className="font-lato lg:text-[19px] text-[15px]font-light underline-from-font decoration-skip-ink-none">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br />
              Lorem Ipsum has been the industrys standard dummy text.
            </p>
          </div>
        </div>

        <div className="flex items-center space-x-2 lg:ml-[150px] relative lg:bottom-[30px] w-[70%] ml-[70px]">
          <button
            className="text-pink-500 hover:text-pink-700"
            id="wpre"
            onClick={handlePrevClick} // On click, move to the previous slide
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
          </button>

          <div className="relative w-[400px] h-3 rounded-full bg-gradient-to-r from-pink-300 to-blue-300">
            <div className="absolute h-3 bg-pink-500 rounded-full" style={{ width: `${progress}%` }}></div>
          </div>

          <button
            className="text-blue-500 hover:text-blue-700"
            id="wnext"
            onClick={handleNextClick}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>

        {/* Swiper component */}
        <Swiper
          spaceBetween={50}
          slidesPerView={1} // Default for mobile view
          pagination={{
            clickable: true,
          }}
          loop={true} 
          ref={swiperRef}
          onSlideChange={handleSlideChange} // Track slide change and update progress
          breakpoints={{
            768: {
              slidesPerView: 1.5,
            },
            320: {
              slidesPerView: 1,
            },
          }}
        >
          <SwiperSlide>
            <div className="relative flex items-center justify-center">
              <div className="absolute inset-[-4px] bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 rounded-full"></div>
              <div className="relative p-12 bg-white rounded-full px-12 lg:px-20 lg:py-20">
                <h3 className="text-xl font-bold text-blue-600">Jennifer Lutheran</h3>
                <p className="text-sm text-gray-400">CEO of ProductX</p>
                <p className="mt-4 font-lato lg:text-[19px] text-[15px]font-light underline-from-font decoration-skip-ink-none">
                  Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="relative flex items-center justify-center">
              <div className="absolute inset-[-4px] bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 rounded-full"></div>
              <div className="relative p-12 bg-white rounded-full px-12 lg:px-20 lg:py-20">
                <h3 className="text-xl font-bold text-blue-600">John Doe</h3>
                <p className="text-sm text-gray-400">CEO of CompanyX</p>
                <p className="mt-4 font-lato lg:text-[19px] text-[15px]font-light underline-from-font decoration-skip-ink-none">
                  Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar.
                </p>
              </div>
            </div>
          </SwiperSlide>
          {/* Add more SwiperSlides here */}
        </Swiper>
      </div>
    </section>
  );
};
