import React from 'react';
import ServicesImage from "@/assets/images/2808108_18383.png";
import smartphone from "@/assets/images/smartphone.png";

export const OurServices = () => {
  return (
    <section className="">
      <div className="mx-auto mt-[100px]">
        <h2 className="text-3xl font-bold mb-12 bg-gradient-to-r from-pink-500 to-blue-500 rounded-tl-[1000px] rounded-tr-[0px] rounded-br-[0px] rounded-bl-[1000px] py-8 px-8 inline-block text-white w-[90%] lg:w-[50%] float-right">
          Services We Provide
        </h2>
        <div className="container mx-auto lg:py-16 lg:px-4 px-6 py-6 grid md:grid-cols-2 gap-12 lg:h-[550px]">
          <div className="relative lg:w-[600px] lg:h-[600px] lg:bottom-[200px]">
            <div className="absolute inset-[-4px] bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 rounded-full"></div>
            <div className="rounded-full overflow-hidden lg:w-[600px] lg:h-[600px] relative">
              <img src={ServicesImage} alt="Image" />
            </div>
          </div>
          <div>
            <div className="border-b">
              <input type="radio" name="toggle" id="item1" className="peer hidden" />
              <label
                htmlFor="item1"
                className="text-[#00bfff] px-4 py-2 text-lg font-medium cursor-pointer peer-checked:bg-gradient-to-r from-pink-500 to-blue-500"
              >
                Responsive Web Design
              </label>
              <div className="flex items-start gap-4 px-4 py-2 hidden peer-checked:flex">
                <img src={smartphone} alt="Smartphone" className="w-12 h-16" />
                <p className="font-lato lg:text-[19px] text-[15px]font-light underline-from-font decoration-skip-ink-none">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text.
                </p>
              </div>
            </div>
            <div className="border-b">
              <input type="radio" name="toggle" id="item2" className="peer hidden" />
              <label
                htmlFor="item2"
                className="text-[#00bfff] px-4 py-2 text-lg font-medium cursor-pointer peer-checked:bg-gradient-to-r from-pink-500 to-blue-500"
              >
                Responsive Web Design
              </label>
              <div className="flex items-start gap-4 px-4 py-2 hidden peer-checked:flex">
                <img src={smartphone} alt="Smartphone" className="w-12 h-16" />
                <p className="font-lato lg:text-[19px] text-[15px]font-light underline-from-font decoration-skip-ink-none">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text.
                </p>
              </div>
            </div>
            <div className="border-b">
              <input type="radio" name="toggle" id="item3" className="peer hidden" />
              <label
                htmlFor="item3"
                className="text-[#00bfff] px-4 py-2 text-lg font-medium cursor-pointer peer-checked:bg-gradient-to-r from-pink-500 to-blue-500"
              >
                Responsive Web Design
              </label>
              <div className="flex items-start gap-4 px-4 py-2 hidden peer-checked:flex">
                <img src={smartphone} alt="Smartphone" className="w-12 h-16" />
                <p className="font-lato lg:text-[19px] text-[15px]font-light underline-from-font decoration-skip-ink-none">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text.
                </p>
              </div>
            </div>
            <div className="border-b">
              <input type="radio" name="toggle" id="item4" className="peer hidden" />
              <label
                htmlFor="item4"
                className="text-[#00bfff] px-4 py-2 text-lg font-medium cursor-pointer peer-checked:bg-gradient-to-r from-pink-500 to-blue-500"
              >
                Responsive Web Design
              </label>
              <div className="flex items-start gap-4 px-4 py-2 hidden peer-checked:flex">
                <img src={smartphone} alt="Smartphone" className="w-12 h-16" />
                <p className="font-lato lg:text-[19px] text-[15px]font-light underline-from-font decoration-skip-ink-none">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text.
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};
