export const ExploreButton = () => {
    return (
        <button className="mt-[10px] lg:px-8 px-4 py-3 bg-gradient-to-r from-pink-500 to-blue-500 text-white rounded-full">
            Explore
        </button>
    );
};


export const ExploreMoreButton = () => {
    return (
        <button className="mt-[10px] px-8 py-3 bg-gradient-to-r from-pink-500 to-blue-500 text-white rounded-full">
            Explore More
        </button>
    );
};

export const GetInTouch = () => {
    return (
        <button className="px-6 py-4 bg-gradient-to-r from-pink-500 to-blue-500 text-white rounded-full">
            Get in Touch
        </button>
    );
};

export const OurWork = () => {
    return (
        <button className="px-6 py-4 bg-gradient-to-r from-pink-500 to-blue-500 text-white rounded-full">
            Our Work
        </button>
    );
};

export const ViewAll = () => {
    return (
        <button className="mt-[10px] px-8 py-3 bg-gradient-to-r from-pink-500 to-blue-500 text-white rounded-full">
            View ALL
        </button>
    );
};
