import { ExploreMoreButton } from "@/util/Button/Buttons";

export const Transforming = () => {
  return (
    <>
     <section className="lg:pt-32 lg:pb-16 px-4 pt-8">
        <div className="container mx-auto text-center max-w-4xl">
            <p className="text-purple-500 lg:mb-4 font-lato lg:text-[36px] text-[27px] font-normal leading-[15.2px] tracking-[0.30em] text-center">Welcome</p>
            <h1 className="bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 bg-clip-text text-transparent text-[40px] font-lato lg:text-[71px] font-extrabold lg:leading-[80px] text-center font-lato">
                Transforming Ideas into Digital Reality
            </h1>
            <p className="lg:mt-[10px] font-lato lg:text-[19px] text-[16px] lg:w-[560px] lg:ml-[162px] font-light lg:leading-[28.8px] text-center underline-from-font decoration-skip-ink-none">
                We build high-performance websites and web applications designed to engage and convert. Our team brings expertise in full stack web development to create solutions that empower your business to thrive online.
            </p>
            <ExploreMoreButton/>
        </div>
    </section>
    </>
  );
};

export default Transforming;
