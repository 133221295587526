import headerImage from "@/assets/images/logo.png";
import { RequestButton, ContactUs } from "@/util/Button/AnchoreTags";
export const Header = () => {

  return (
    <>
      <header className="bg-pink-100">
        <nav className="container mx-auto px-6 py-4 flex justify-between items-center gap-6">
          <div className="menu-container lg:w-[50px] lg:h-[50px] w-[35px] h-[35px] rounded-full flex flex-col items-center justify-center cursor-pointer p-[12px]" style={{ background: "linear-gradient(90deg, #FF45D7 0%, #00AAFF 100%)" }}
          >
            <div className="menu-line lg:w-[35px] w-[25px] h-[3px] lg:h-[5px] bg-white lg:my-[4px] my-[1px] transition-all duration-400 ease-in-out"></div>
            <div className="menu-line lg:w-[35px] w-[25px] h-[3px] lg:h-[5px] bg-white lg:my-[4px] my-[2px] transition-all duration-400 ease-in-out"></div>
            <div className="menu-line lg:w-[35px] w-[25px] h-[3px] lg:h-[5px] bg-white lg:my-[4px] my-[1px] transition-all duration-400 ease-in-out"></div>
          </div>
          <div className="text-3xl font-bold bg-gradient-to-r from-pink-500 to-blue-500 bg-clip-text text-transparent">
            <img src={headerImage} className="lg:w-[366px] lg:h-[72px] lg:top-[37px] lg:left-[784px] gap-0 items-center text-center lg:ml-[150px]" />
          </div>
          <div className="flex gap-4 items-center">
            <ContactUs/>
            <RequestButton/>
          </div>
        </nav>
      </header>
    </>
  );
};
