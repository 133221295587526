import "swiper/css";
import './index.css'

import { Transforming } from "@/components/TransformingIdeas/TransformingIdeas";
import { TrustedPartner } from "@/components/TrustedPartner/TrustedPartner";
import { OurCatalogues } from "@/components/OurCatalogues/OurCatalogues";
import { OurPortfolio } from "@/components/OurPortfolio/OurPortfolio";
import { TestiMonial } from "@/components/Testimonial/Testimonial";
import { OurServices } from "@/components/OurServices/OurServices";
import { Layout } from "@/components/Common/Layout/Layout";
import { DigitalCommerce } from "@/components/DigitalCommerce/DigitalCommerce";
function App() {
  return (
    <div className="App">
      <Layout>

        
        <Transforming />
        <TrustedPartner />
        <DigitalCommerce />
        <OurServices />
        <OurCatalogues />
        <OurPortfolio />
        <TestiMonial />
      </Layout>
    </div>
  );
}

export default App;
