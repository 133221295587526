import React from "react";
import footerLogo from "@/assets/images/logo.png"
import { FormProvider, useForm } from "react-hook-form";

export const Footer = () => {
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
      } = useForm();
    
      const onSubmit = (data) => {
        reset();
      };
    
      const methods = useForm();

  return (
    <>
  <footer className="bg-black text-white py-12 px-4">
    <div className="max-w-6xl mx-auto mb-12 flex justify-center">
        <img src={footerLogo} className="w-[366px] h-[72px] top-[37px] left-[784px] gap-0 items-center text-center" />
    </div>

    <div className="max-w-6xl mx-auto">
        {/* Top Horizontal Line */}
        <div className="h-[1px] w-full bg-gradient-to-r from-[#E5DB2F] to-[#78C04F]"></div>
    </div>

    <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-[1fr_auto_1fr] gap-8 relative mt-8">
        <div className="space-y-6 pr-4">
            <FormProvider {...methods}>
                <form className="relative" method="post" onSubmit={handleSubmit(onSubmit)}>
                    <input
                        type="email"
                        placeholder="Enter Your Email"
                        className="w-full px-4 py-3 rounded-full bg-gradient-to-r from-pink-500/20 to-blue-500/20 border-0 focus:ring-2 focus:ring-blue-500 text-white"
                    />
                    <button className="absolute right-2 top-1/2 -translate-y-1/2 p-2" type="submit">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 text-blue-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M14 5l7 7m0 0l-7 7m7-7H3"
                            />
                        </svg>
                    </button>
                </form>
            </FormProvider>
            <h2 className="text-4xl font-lato">Let's Get in Touch</h2>
            <p className="text-gray-400 max-w-md">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                standard with the release of Letraset sheets containing
            </p>
        </div>

        {/* Vertical Line */}
        <div className="w-[1px] bg-gradient-to-b from-[#E5DB2F] to-[#78C04F]"></div>

        <div className="space-y-6 pl-4">
            <div className="flex items-start space-x-4">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-white mt-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                </svg>
                <span className="text-gray-300">hr@bronzebyte.com</span>
            </div>
            <div className="flex items-start space-x-4">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 text-white mt-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                </svg>
                <span className="text-gray-300">+917710113366</span>
            </div>
            <div className="flex items-start space-x-4">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-10 text-white mt-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                    />
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                </svg>
                <span className="text-gray-300">#205 Flexi Tower, F-288, Industrial Area, Sector 75, Sahibzada Ajit Singh Nagar, Mohali, Punjab 160055, IN</span>
            </div>
        </div>
    </div>

    {/* Bottom Horizontal Line */}
    <div className="max-w-6xl mx-auto mt-8">
        <div className="h-[1px] w-full bg-gradient-to-r from-[#E5DB2F] to-[#78C04F]"></div>
    </div>

    <div className="max-w-6xl mx-auto mt-4">
        <p className="text-center text-white text-sm">© 2024 copyright all rights reserved</p>
    </div>
</footer>

    </>
  );
};
