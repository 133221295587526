export const RequestButton = () => {
    return (
        <a href="#" className="lg:px-4 lg:py-2 lg:text-white text-blue-600 lg:bg-gradient-to-r from-pink-500 to-blue-500 rounded-full">
              Request a Quote
            </a>
    )
}

export const ContactUs = () => {
    return (
        <a href="#" className="text-blue-600 hover:text-gray-900">Contact Us</a>
    )
}


