import { useState } from "react";
import Portfolio from "@/assets/images/rb_2150699159.png";
import { ViewAll } from "@/util/Button/Buttons";

export const OurPortfolio = () => {
  return (
   <>
     <section className="pt-32 pb-16 px-4">
        <div className="container mx-auto text-center max-w-4xl">
            <img src={Portfolio} />
            <h1 className="bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 bg-clip-text text-transparent font-lato text-[40px] font-extrabold text-center">
                Our Portfolio
            </h1>
            <p className="font-lato text-[19px] lg:w-[560px] lg:ml-[162px] font-light leading-[28.8px] text-center underline-from-font decoration-skip-ink-none">
               Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.
            </p>
            <ViewAll/>
        </div>
    </section>
 <section className="lg:h-[750px] h-[400px]">
        <h2 className="lg:text-3xl text-2xl font-bold lg:mb-12 bg-gradient-to-r from-pink-500 to-blue-500 rounded-tl-[1000px] rounded-tr-[0px] rounded-br-[0px] rounded-bl-[1000px] lg:py-8 lg:px-8 py-4 px-4 inline-block text-white lg:w-[25%] w-[90%] float-right">
          Fixed Service Packages we Provide
        </h2>
         <div className="text-center container mx-auto">
            <div className="flex items-center justify-center space-x-6 py-8 px-8 lg:py-0 lg:px-0">
                <div className="relative lg:w-[500px] lg:h-[700px] flex items-center justify-center">
                    <div className="absolute inset-[-4px] bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 rounded-full"></div>
                    <div className="bg-white lg:w-[500px] lg:h-[700px] w-[120px] h-[200px] rounded-full flex flex-col items-center justify-center relative">
                        <span className="lg:text-[80px] text-[25px] text-gray-500">NEW YEAR</span>
                        <span className="lg:text-[80px] text-[25px] text-gray-500 font-semibold">OFFER</span>
                        <span className="lg:text-[80px] text-[25px] text-gray-500 font-semibold">60% OFF</span>
                    </div>
                </div>

                <div className="relative lg:w-[354px] lg:h-[500px] flex items-center justify-center lg:mt-[160px] lg:left-[80px] mt-[40px]">
                    <div className="absolute inset-[-4px] bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 rounded-full"></div>
                    <div className="bg-white lg:w-[354px] lg:h-[500px] w-[120px] h-[150px] rounded-full flex flex-col items-center justify-center relative">
                        <span className="lg:text-[60px] text-[20px] text-gray-500">NEW YEAR</span>
                        <span className="lg:text-[60px] text-[20px] text-gray-500 font-semibold">OFFER</span>
                        <span className="lg:text-[60px] text-[20px] text-gray-500 font-semibold">45% OFF</span>
                    </div>
                </div>

                <div className="relative lg:w-[300px] lg:h-[300px] flex items-center justify-center lg:mt-[320px] lg:left-[180px] mt-[80px]">
                    <div className="absolute inset-[-4px] bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 rounded-full"></div>
                    <div className="bg-white lg:w-[300px] lg:h-[300px] w-[100px] h-[100px] rounded-full flex flex-col items-center justify-center relative">
                        <span className="lg:text-[30px] text-[15px] text-gray-500">NEW YEAR</span>
                        <span className="lg:text-[30px] text-[15px] text-gray-500 font-semibold">OFFER</span>
                        <span className="lg:text-[30px] text-[15px] text-gray-500 font-semibold">30% OFF</span>
                    </div>
                </div>
            </div>
          </div>
    </section>

    </>
  );
};

export default OurPortfolio;
