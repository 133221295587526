import Image1 from "@/assets/images/1.png";
import Image2 from "@/assets/images/2.png";
import Image3 from "@/assets/images/3.png";
import { GetInTouch, OurWork } from "@/util/Button/Buttons";


export const TrustedPartner = () => {
  return (
    <section className="py-16 px-4">
        <div className="container mx-auto">
            <div className="grid md:grid-cols-3 gap-12 justify-items-center items-center text-center">
                <div>
                    <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 bg-clip-text text-transparent">
                        Your Trusted Partner in Web Development
                    </h2>
                    <img src={Image1} />
                </div>
                <div>
                    <img src={Image2} />
                </div>
                <div className="space-y-4">
                    <img src={Image3} />
                    <p className="font-lato lg:text-[19px] text-[15px]font-light underline-from-font decoration-skip-ink-none text-left">
                        With over 5 years of experience in the web development industry, we specialize in crafting exceptional digital solutions. Based in San Francisco, our team is dedicated to delivering innovative web development and design that meet the unique needs of our clients worldwide.
                    </p>
                    <div className="flex gap-4">
                        <GetInTouch/>
                        <OurWork/>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default TrustedPartner;
