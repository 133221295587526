import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import magento from "@/assets/images/magento-logo.png";

export const DigitalCommerce = () => {
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);
  const [progress, setProgress] = useState(15); // Default progress set to 15%

  return (
    <section>
      <div className="mx-auto">
        <h2 className="lg:text-3xl text-2xl font-bold mb-12 bg-gradient-to-r from-pink-500 to-blue-500 rounded-tl-[0px] rounded-tr-[1000px] rounded-br-[1000px] rounded-bl-[0px] py-6 px-8 inline-block text-white w-[380px]">
          Digital Commerce Platform
        </h2>
        <div className="flex items-center space-x-2 ml-[100px] relative bottom-[30px]">
          <button
            className="text-pink-500 hover:text-pink-700"
            ref={prevButtonRef}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <div className="relative w-[150px] h-3 rounded-full bg-gradient-to-r from-pink-300 to-blue-300">
            <div
              className="absolute h-3 bg-pink-500 rounded-full"
              style={{ width: `${progress}%` }} // Update width based on progress state
            ></div>
          </div>
          <button
            className="text-blue-500 hover:text-blue-700"
            ref={nextButtonRef}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>

        <Swiper
          modules={[Navigation]}
          loop={true} // Enable looping
          navigation={{
            prevEl: prevButtonRef.current,
            nextEl: nextButtonRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = prevButtonRef.current;
            swiper.params.navigation.nextEl = nextButtonRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          onSlideChange={(swiper) => {
            const progressPercentage = (swiper.realIndex / (swiper.slides.length - 1)) * 100;
            setProgress(progressPercentage); // Update progress based on the current slide
          }}
          breakpoints={{
            640: {
              slidesPerView: 1.5,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3.5, // Show 4 slides on desktop
            },
          }}
          className="container mx-auto py-4 px-4"
        >
          {[...Array(8)].map((_, index) => (
            <SwiperSlide key={index}>
              <div
                className={`relative w-[330px] h-[330px] ${
                  index % 2 === 0 ? "lg:mt-[115px]" : ""
                }`}
              >
                <div className="absolute inset-[-4px] bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 rounded-full"></div>
                <div className="bg-white rounded-full p-8 shadow-lg aspect-square flex flex-col items-center justify-center relative">
                  <img src={magento} className="mt-[40px]" alt="Magento Logo" />
                  <hr className="my-[10px] border border-gray-500 w-full" />
                  <p className="text-center">
                    Lorem Ipsum es simplemente el texto de relleno de las
                    imprentas y archivos de texto. Lorem Ipsum ha sido el texto
                    de relleno estándar.
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};


export default DigitalCommerce;
